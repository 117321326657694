import React, { useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { motion, AnimatePresence } from 'framer-motion';
import Poll from 'assets/svgs/Poll';
import QAndA from 'assets/svgs/QAndA';
import { Button, SEO } from 'components';

const HelpHub = () => {
  const { theme } = useContext(LocalContext);
  return (
    <>
      <SEO pageSpecificTitle="Help Hub" />
      <MenuTab
        theme={theme}
        initial={{ y: -120 }}
        animate={{ y: 0 }}
        exit={{ y: -120 }}
        transition={{ delay: 0.75, duration: 0.75 }}>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}>
          Help Hub
        </motion.span>
      </MenuTab>
      <Wrapper
        theme={theme}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.5, duration: 1 }}>
        <Content>
          <h2>Virtual Event Guidelines</h2>
          <h3>Guidelines will help you to easily navigate and use our virtual platform</h3>
          <Options>
            <Option theme={theme}>
              <QAndA />
              <div>
                <h4>Q&A Guidelines</h4>
                <p>Learn how to interact with users through Q&A feature</p>
                <Link to="/help-hub/q-and-a">
                  <Button type="button" width="124px">
                    VIEW
                  </Button>
                </Link>
              </div>
            </Option>
            <Option theme={theme}>
              <Poll />
              <div>
                <h4>Poll Guidelines</h4>
                <p>Learn how to use polls feature</p>
                <Link to="/help-hub/polls">
                  <Button type="button" width="124px">
                    VIEW
                  </Button>
                </Link>
              </div>
            </Option>
          </Options>
        </Content>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(motion.div)`
  align-items: center;
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-column: 1 / 7;
  justify-content: center;
  margin: 9rem 0 2rem 0;
  width: 100%;
  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h3 {
    font-weight: normal;
    margin-bottom: 2.625rem;
    text-align: center;
    width: 50vw;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1 / 13;
    margin: 10rem 0 4rem 0;
  }
`;

const MenuTab = styled(motion.div)`
  align-items: center;
  background: ${({ theme }) => theme.primary};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  left: 0;
  max-height: 60px;
  min-height: 60px;
  position: absolute;
  top: 3.75rem;
  width: 100%;
  span {
    color: #ffffff;
    font-size: 1.25rem;
    grid-column: 1 / 6;
    line-height: 1.5rem;
    padding: 0 1rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    span {
      grid-column: 2 / 5;
      padding: 0;
    }
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Options = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: none;
  grid-template-rows: 1fr 1fr;
  margin: 1rem;
  width: 100%;
  @media only screen and (min-width: 700px) {
    grid-gap: 2rem;
  }
  @media only screen and (min-width: 1024px) {
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
  }
`;

const Option = styled.div`
  background: rgba(196, 196, 196, 0.2);
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  > svg {
    align-self: center;
    margin: 2.5rem;
  }
  > div {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding: 1.75rem;
    text-align: left;
    h4,
    p {
      color: ${({ theme }) => theme.primary};
      padding-bottom: 0.5rem;
    }
    h4 {
      color: ${({ theme }) => theme.primary};
      font-size: 1.25rem;
      font-weight: 700;
    }
    p {
      font-size: 1.25rem;
    }
    button {
      color: #ffffff;
      margin-top: 1.25rem;
      a {
        color: #ffffff;
      }
    }
  }
`;

export default HelpHub;
